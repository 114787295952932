import * as React from "react";
import * as styles from "./shopping-cart.module.css";

import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import CheckoutButton from "../Checkout/CheckOutButton";
import CouponButton from "../AddCoupon";
import { GET_CUSTOMER } from "../Account/ShoppingHistory";
import { navigate } from "gatsby";
import useAuth from "../useAuth";
import { useQuery } from "@apollo/client";

type OrderSummaryItemProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};

type OrderSummaryProps = {
  subtotal?: string;
  shippingTotal?: string;
  total?: string;
  discountTotal?: string;
  appliedCoupons?: any;
  updateLoading?: boolean;
};

const OrderSummaryItem = (props: OrderSummaryItemProps) => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode("gray.600", "gray.400")}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};

export const CartOrderSummary = (props: OrderSummaryProps) => {
  const { user } = useAuth();
  const customer = useQuery(GET_CUSTOMER, {
    variables: { id: user?.id },
  });
  const { subtotal, shippingTotal, total, discountTotal, appliedCoupons } =
    props;

  return (
    <>
      <Stack
        bg="white"
        spacing="8"
        rounded="lg"
        padding="8"
        width="full"
        boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);"
      >
        <Heading size="md">Order Summary</Heading>

        <Stack spacing="6">
          <OrderSummaryItem label="Subtotal" value={subtotal} />
          <OrderSummaryItem label="Shipping + Tax" value={shippingTotal}>
            <Link href="#" textDecor="underline">
              Calculate shipping
            </Link>
          </OrderSummaryItem>

          {appliedCoupons?.map((item: { code: string }) => {
            return (
              <OrderSummaryItem label="Applied coupon" value={item.code} />
            );
          })}

          <OrderSummaryItem label="Discount total" value={discountTotal} />

          <OrderSummaryItem label="Coupon Code">
            <CouponButton />
          </OrderSummaryItem>
          <Flex justify="space-between">
            <Text fontSize="lg" fontWeight="semibold">
              Total
            </Text>
            <Text fontSize="xl" fontWeight="extrabold" color="#D28C49">
              {total}
            </Text>
          </Flex>
        </Stack>

        <CheckoutButton isDisabled={props.updateLoading} />
        <Button
          role="button"
          variant="ghost"
          isDisabled={props.updateLoading}
          onClick={() => navigate("/shop")}
          _hover={{ bg: "none" }}
        >
          Continue shopping
        </Button>
      </Stack>
    </>
  );
};
